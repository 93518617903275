<template>
  <el-dialog
    v-bind="{title:editData?(editData.isAddSub ? '新增数据字典': '编辑数据字典'):'新增数据字典',width:'420px'}"
    v-dialogDrag
    @closed="handlerClose"
    :visible.sync="visible">
    <BaseForm ref="form" label-width="130px" :model="submitForm" :rules="rules">
      <el-form-item label="字典编码" prop="dictCode">
        <el-input class="w-200" :disabled="editData || isAddSub" v-model.trim="submitForm.dictCode" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="字典存储值" prop="dictValue">
        <el-input class="w-200" v-model.trim="submitForm.dictValue" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="字典存储值名称" prop="dictName">
        <el-input class="w-200" v-model.trim="submitForm.dictName" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="父级值" prop="dictParentValue">
        <el-input class="w-200" :disabled="isAddSub" v-model.trim="submitForm.dictParentValue" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="层级" prop="dictLevel">
        <el-input class="w-200" v-model.trim="submitForm.dictLevel" clearable placeholder="请输入"></el-input>
      </el-form-item>
    </BaseForm>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateDictionary } from '@/apis/authManage/dataDictionary'
import { mapActions } from 'vuex'
import BaseForm from '../../../components/common/BaseForm/index.vue'

// 记录submitForm，比对
class FormUtil {
  constructor (initFormData) {
    this.initFormData = XEUtils.clone(initFormData, true)
  }

  compareToForm (submitForm) {
    console.log(this.initFormData, XEUtils.clone(submitForm, true), XEUtils.isEqualWith(this.initFormData, XEUtils.clone(submitForm, true)))
    return XEUtils.isEqualWith(this.initFormData, XEUtils.clone(submitForm, true))
  }
}
export default {
  computed: {
    isAddSub () {
      return this.editData?.isAddSub
    }
  },
  data () {
    return {
      editData: null,
      loading: false,
      visible: false,
      submitForm: {
        id: '',
        dictCode: '',
        dictLevel: '',
        dictName: '',
        dictParentValue: '',
        dictValue: '' // 字典数据库存储值（最顶级默认为0，代表所有）
      },
      formUtil: null,
      rules: {
        dictCode: { required: true, message: '请输入字典编码' }
      }
    }
  },
  methods: {
    ...mapActions('account', ['refreshToken']),
    // 验证字典，比较字段，重置字段，关闭弹窗，刷新列表事件
    submit () {
      const submitForm = { ...this.submitForm }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formUtil.compareToForm(submitForm)) {
            this.$notify.success({ title: '成功', message: '保存成功：无内容变更！' })
            this.visible = false
            return true
          } else {
            this.loading = true
            updateDictionary(submitForm).then((res) => {
              this.loading = false
              if (res.code == 200) {
                this.$notify.success({ title: '成功', message: '保存成功！' })
                this.$emit('refresh')
                this.visible = false
              } else {
                this.$notify.error({ title: '失败', message: res.msg })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    open (editData) {
      this.visible = true
      this.editData = editData
      if (editData) {
        // 新增下级
        if (editData.isAddSub) {
          this.submitForm.dictParentValue = editData.dictValue
          this.submitForm.dictCode = editData.dictCode
          // 编辑
        } else {
          Object.keys(this.submitForm).forEach((key) => {
            const value = editData[key]
            if (value) { this.submitForm[key] = value }
          })
        }
      }
      // FormUtil
      this.formUtil = new FormUtil(this.submitForm)
    },
    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$refs.form.resetFields()
      this.editData = null
      console.log(this.submitForm)
    }
  },
  components: { BaseForm }
}
</script>
