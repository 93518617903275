<template>
  <div>
    <BaseTable
      ref="NestTable"
      :data="data"
      :toolsbar="toolsbar"
      :id="id"
    >
      <template #buttons>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleCreate">新 增</el-button>
        <el-button size="small" type="primary" icon="el-icon-download" @click="handleExport">导出JSON文件</el-button>
        <el-button size="small" type="primary" icon="el-icon-refresh" @click="refreshDictCache">刷新缓存</el-button>
      </template>
      <vxe-column type="expand" align="center" width="80">
        <template #content="{row}">
          <div class="expand-wrapper">
            <div v-if="!row.children || row.children.length <= 0">
              无下级数据
            </div>
            <div v-else>
              <NestTable :toolsbar="false" v-if="isExpand(row)" :data="row.children"></NestTable>
            </div>
          </div>
        </template>
      </vxe-column>
      <vxe-column field="dictCode" title="字典编码"></vxe-column>
      <vxe-column field="dictValue" title="字典存储值"></vxe-column>
      <vxe-column field="dictName" title="字典存储值名称"></vxe-column>
      <vxe-column field="dictParentValue" title="父级值"></vxe-column>
      <vxe-column field="activityName" title="状态"></vxe-column>
      <vxe-column title="操作" width="220" :show-overflow="false">
        <template #default={row}>
          <el-button type="primary" @click="handleEdit(row, true)">新增下级</el-button>
          <el-button type="primary" @click="handleEdit(row)">编辑</el-button>
          <el-button v-if="!row.activity" type="primary" @click="handleEnable(row, true)">启用</el-button>
          <el-button v-else type="danger" @click="handleEnable(row, false)">禁用</el-button>
          <!-- <el-button type="danger" @click="handleDel(row.id)">删除</el-button> -->
        </template>
      </vxe-column>
    </BaseTable>

  </div>
</template>

<script>
import NestTable from './NestTable'
import { refreshDictCache } from '@/apis/authManage/dataDictionary'
export default {
  name: 'NestTable',
  components: {
    NestTable
    // Add
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    toolsbar: {
      type: Boolean,
      default: true
    },
    id: {}
  },
  inject: [
    'initList',
    'handleCreate',
    'handleEdit',
    'handleDel',
    'handleEnable',
    'handleExport'
  ],
  data () {
    return {
      searchForm: {
        dictValue: 0,
        dictCode: ''
      },
      // 表格相关
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      loading: false,
      editData: null,
      editVisible: false
    }
  },
  methods: {
    isExpand (row) {
      return this.$refs.NestTable.isExpandByRow(row)
    },
    refreshDictCache () {
      refreshDictCache().then(res => {
        if (Number(res.code) === 200) {
          this.$notify.success({
            title: '成功',
            message: '刷新成功'
          })
        } else {
          this.$notify.success({
            title: '失败',
            message: res.msg || '刷新失败'
          })
        }
      })
    }
  }
}
</script>
