<template>
  <PageContainer>
    <BaseSearchForm>
      <el-form-item label="字典编码:">
        <el-input v-model.trim="searchForm.dictCode" placeholder="请输入" clearable></el-input>
      </el-form-item>
    </BaseSearchForm>

    <div class="bg-white">
      <NestTable
        ref="tableRef"
        :data="filterTable"
        :loading="loading"
        id="dataDictionary"
      ></NestTable>
    </div>

    <Add ref="addDialogForm" @refresh="initList"></Add>
  </PageContainer>
</template>

<script>
import { delDictionary, enableDictionary, getDictionaryList } from '@/apis/authManage/dataDictionary'
import NestTable from './NestTable'
import Add from './Add'
export default {
  components: {
    Add,
    NestTable
  },
  computed: {
    filterTable () {
      return this.tableData.filter(item => item.dictCode.includes(this.searchForm.dictCode))
    }
  },
  provide () {
    return {
      initList: this.initList,
      handleCreate: this.handleCreate,
      handleEdit: this.handleEdit,
      handleDel: this.handleDel,
      handleEnable: this.handleEnable,
      handleExport: this.handleExport
    }
  },
  data () {
    return {
      searchForm: {
        dictCode: ''
      },
      // 表格相关
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 9999,
        totalSize: 0
      },
      loading: false,
      editData: null,
      editVisible: false,
      dictionary: null
    }
  },
  mounted () {
    this.initList()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },
    async initList () {
      this.loading = true

      const { tablePage } = this
      const data = {
        pageNum: tablePage.pageNum,
        pageSize: tablePage.pageSize
      }
      getDictionaryList(data).then((res) => {
        const props = { key: 'key', parentKey: 'parentKey' }
        this.setDictionary(res.data.rows)
        this.tableData = XEUtils.toArrayTree(res.data.rows.map((item) => {
          item.activityName = item.activity ? '启用' : '禁用'
          item.parentKey = item.dictCode + ':' + item.dictParentValue
          item.key = item.dictCode + ':' + item.dictValue
          return item
        }), props)
      }).finally(() => {
        this.loading = false
      })
    },
    handleCreate () {
      this.$refs.addDialogForm.open()
    },
    handleEdit (row, isAddSub) {
      row = JSON.parse(JSON.stringify(row))
      row.isAddSub = isAddSub
      this.editData = row
      this.$refs.addDialogForm.open(row)
    },
    handleExport () {
      function download (url, name) {
        const a = document.createElement('a')
        a.download = name
        a.rel = 'noopener'
        a.href = url
        // 触发模拟点击
        a.dispatchEvent(new MouseEvent('click'))
      }
      const dataUrl = `data:,${JSON.stringify(this.dictionary)}`
      download(dataUrl, 'index.json')
    },
    setDictionary (data) {
      const obj = {}
      const props = { key: 'dictValue', parentKey: 'dictParentValue', strict: true }
      const dictCodes = data.filter(item => item.dictCode && item.dictParentValue == -1)
      dictCodes.forEach((item) => {
        const dictCode = item.dictCode
        const dictName = item.dictName
        const optionList = data.filter(item => item.dictCode == dictCode && item.dictParentValue != -1)
        const optionTree = XEUtils.toArrayTree(optionList, props)
        obj[dictCode] = {
          title: dictName,
          options: optionTree
        }
      })
      this.dictionary = obj
    },
    handleEnable ({ id }, activity) {
      const activityName = activity ? '启用' : '禁用'
      this.$confirm(`确定要${activityName}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        enableDictionary({ id, activity }).then((res) => {
          if (res.code) {
            this.initList()
            this.$notify.success({ title: '成功', message: activityName + '成功！' })
          } else {
            this.$notify.error({ title: '失败', message: res.msg })
          }
        })
      })
    },
    handleDel (id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delDictionary({ id }).then((res) => {
          if (res.code) {
            this.initList()
            this.$notify.success({ title: '成功', message: '刪除成功！' })
          } else {
            this.$notify.error({ title: '失败', message: res.msg })
          }
        })
      })
    }
  }
}
</script>
